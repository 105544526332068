<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div v-for="hashtag in sortedTrending" :key="hashtag.id" class="columns">
        <div class="column">
          <div class="box has-text-centered">
            <div class="title">
              <a :href="getUrl(hashtag)" target="_blank">#{{ hashtag.title }}</a>
            </div>
            <div>{{ hashtag.views }} views</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    sortedTrending() {
      const sorted = this.trending.hashtags.slice();
      // eslint-disable-next-line require-jsdoc
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const hash1 = a.title.toUpperCase();
        const hash2 = b.title.toUpperCase();
        let comparison = 0;
        if (hash1 > hash2) {
          comparison = 1;
        } else if (hash1 < hash2) {
          comparison = -1;
        }
        return comparison;
      }
      sorted.sort(compare);
      return sorted;
    },
    ...mapGetters("trending", ["trending"]),
  },
  methods: {
    getUrl(hashtag) {
      return `https://www.tiktok.com/tag/${hashtag.title}`;
    },
  },
};
</script>

<style></style>
