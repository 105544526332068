<template>
  <section class="box">
    <div v-if="trending === null" class="container">
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <div class="has-text-centered">
            <img class="svg-custom" src="../../assets/trend.svg" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-tabs position="is-centered" class="block">
        <b-tab-item label="Videos">
          <trending-video />
        </b-tab-item>
        <b-tab-item label="Hashtags">
          <trending-hashtag />
        </b-tab-item>
        <b-tab-item label="Users">
          <trending-user />
        </b-tab-item>
        <b-tab-item label="Songs">
          <trending-song />
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TrendingVideo from "@/components/trending/TrendingVideo";
import TrendingHashtag from "@/components/trending/TrendingHashtag";
import TrendingUser from "@/components/trending/TrendingUser";
import TrendingSong from "@/components/trending/TrendingSong";

export default {
  components: {
    TrendingVideo,
    TrendingHashtag,
    TrendingUser,
    TrendingSong,
  },
  computed: {
    ...mapGetters("trending", ["trending"]),
  },
};
</script>

<style>
.trend-custom {
  max-height: 300px;
  max-width: 300px;
}
</style>
