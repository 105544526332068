<template>
  <div class="columns is-multiline">
    <div v-for="video in trending.videos" :key="video.embed_url" class="column is-one-third">
      <div class="box">
        <div class="columns has-text-centered">
          <div class="column">
            <VideoCard :url="video.embed_url" :user="video.user" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VideoCard from "@/components/trending/VideoCard";
export default {
  components: { VideoCard },
  computed: {
    ...mapGetters("trending", ["trending"]),
  },
};
</script>

<style></style>
