<template>
  <section class="hero is-large">
    <div class="hero-body">
      <div v-if="user.plan === 'free'">
        <upgrade />
      </div>
      <div v-else class="container">
        <start-tracker />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Upgrade from "@/components/home/Upgrade";
import StartTracker from "@/components/home/StartTracker";

export default {
  components: {
    Upgrade,
    StartTracker,
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.$store.dispatch("tiktok/getTiktok");
  },
};
</script>

<style></style>
