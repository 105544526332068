<template>
  <section class="box">
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <b-field label="Select a date">
            <b-datepicker
              v-model="select_date"
              placeholder="Type or select a date..."
              icon="calendar-today"
              editable
              :min-date="minDate"
              :max-date="maxDate"
            >
              >
            </b-datepicker>
          </b-field>
        </div>
        <div class="level-item">
          <b-field label="Location">
            <b-dropdown v-model="select_location" aria-role="list">
              <button slot="trigger" class="button is-primary">
                <span>{{ select_location }}</span>
                <b-icon icon="menu-down" />
              </button>

              <b-dropdown-item
                v-for="item in location"
                :key="item"
                aria-role="listitem"
                :value="item"
              >
                {{ item }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <p class="level-item">
          <b-field>
            <b-button type="is-primary" @click="submit()">
              Submit
            </b-button>
          </b-field>
        </p>
      </div>
    </nav>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    const today = new Date();
    const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    return {
      select_date: maxDate,
      select_location: "US",
      location: [
        "EU",
        "US",
        "UK",
        "NL",
        "DE",
        "AU",
        "SG",
        "IN",
        "CA",
        "RU",
        "PH",
        "MY",
        "SA",
        "EG",
        "AE",
        "JP",
        "VN",
        "KR",
      ],
      maxDate,
    };
  },
  computed: {
    minDate() {
      const today = new Date();

      if (this.user.plan === "starter" || this.user.plan === "starter-yearly") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      }
      if (this.user.plan === "growth" || this.user.plan === "growth-yearly") {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
      }
      if (this.user.plan === "pro" || this.user.plan === "pro-yearly") {
        if (
          this.select_location === "IN" ||
          this.select_location === "SG" ||
          this.select_location === "AU"
        ) {
          return new Date(2020, 1, 5);
        }
        if (this.select_location === "CA") {
          return new Date(2020, 2, 7);
        }
        if (
          this.select_location === "RU" ||
          this.select_location === "PH" ||
          this.select_location === "MY"
        ) {
          return new Date(2020, 6, 31);
        }
        if (
          this.select_location === "SA" ||
          this.select_location === "EG" ||
          this.select_location === "AE" ||
          this.select_location === "JP" ||
          this.select_location === "VN" ||
          this.select_location === "KR"
        ) {
          return new Date(2020, 7, 20);
        }
        return new Date(2019, 9, 4);
      }
      return today;
    },
    ...mapGetters("user", ["user"]),
  },
  beforeDestroy() {
    store.dispatch("trending/clearTrending");
  },
  methods: {
    submit() {
      store.dispatch("trending/getTrending", {
        date: this.select_date,
        location: this.select_location,
      });
    },
  },
};
</script>

<style></style>
