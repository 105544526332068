<template>
  <div>
    <div v-if="loading">
      <b-loading :active.sync="loading" :is-full-page="false" />
    </div>
    <div v-else>
      <a :href="link" target="_blank">
        <figure class="image is-1by2">
          <img width="640" height="360" :src="html" frameborder="0" />
        </figure>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    user: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
      html: "",
      unique_id: "",
    };
  },
  computed: {
    link() {
      const s = this.url.split("embed/");
      const url = "https://www.tiktok.com/@" + this.unique_id + "/video/" + s[1];
      return url;
    },
  },
  mounted() {
    this.getHtml();
  },
  methods: {
    getHtml() {
      const embedurl = "https://www.tiktok.com/oembed?url=" + this.link;
      axios.get(embedurl).then(response => {
        if (response.status === 200) {
          if (response.data.author_url !== undefined || response.data.thumbnail_url !== undefined) {
            this.unique_id = response.data.author_url.split("https://www.tiktok.com/@")[1];
            this.html = response.data.thumbnail_url;
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style></style>
