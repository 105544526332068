var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-half has-text-centered"},[_c('b-button',{attrs:{"rounded":"","outlined":"","type":"is-primary","icon-left":"download"},on:{"click":function($event){return _vm.exportToCSV()}}},[_vm._v(" Export to CSV ")])],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":"Filter by tags"}},[_c('b-taginput',{attrs:{"data":_vm.filteredTags.length === 0 ? _vm.allTags : _vm.filteredTags,"autocomplete":"","allow-new":false,"icon":"label","placeholder":"Search a tag"},on:{"typing":_vm.getFilteredTags},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)],1)]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-table',{attrs:{"data":_vm.orderedPost,"striped":true,"bordered":true,"mobile-cards":true,"paginated":true,"per-page":"40","hoverable":true,"pagination-position":"bottom"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"avatar"}},[_c('router-link',{attrs:{"to":_vm.getRouterLink(props.row.id)}},[_c('figure',{staticClass:"image is-9by16"},[_c('img',{staticClass:"inset",attrs:{"src":("https://www.tiktok.com/api/img/?itemId=" + (props.row.post_id) + "&location=0&aid=1988")}})])])],1),_c('b-table-column',{attrs:{"field":"total_digg","label":"Total likes","sortable":"","numeric":""}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(props.row.total_digg))+" ")]),_c('b-table-column',{attrs:{"field":"new_digg","label":"Last 24h likes","sortable":"","numeric":""}},[_c('span',{class:[
                    { 'has-text-danger': props.row.new_digg <= 0 },
                    { 'has-text-success': props.row.new_digg > 0 } ]},[_vm._v(_vm._s(_vm._f("formatNumber")(props.row.new_digg)))])]),_c('b-table-column',{attrs:{"field":"total_share","label":"Total shares","sortable":"","numeric":""}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(props.row.total_share))+" ")]),_c('b-table-column',{attrs:{"field":"new_share","label":"Last 24h shares","sortable":"","numeric":""}},[_c('span',{class:[
                    { 'has-text-danger': props.row.new_share <= 0 },
                    { 'has-text-success': props.row.new_share > 0 } ]},[_vm._v(_vm._s(_vm._f("formatNumber")(props.row.new_share)))])]),_c('b-table-column',{attrs:{"field":"total_comment","label":"Total comments","sortable":"","numeric":""}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(props.row.total_comment))+" ")]),_c('b-table-column',{attrs:{"field":"new_comment","label":"Last 24h comments","sortable":"","numeric":""}},[_c('span',{class:[
                    { 'has-text-danger': props.row.new_comment <= 0 },
                    { 'has-text-success': props.row.new_comment > 0 } ]},[_vm._v(_vm._s(_vm._f("formatNumber")(props.row.new_comment)))])]),_c('b-table-column',{attrs:{"field":"views","label":"Total views","sortable":"","numeric":""}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(props.row.total_views))+" ")]),_c('b-table-column',{attrs:{"field":"new_views","label":"Last 24h views","sortable":"","numeric":""}},[_c('span',{class:[
                    { 'has-text-danger': props.row.new_views <= 0 },
                    { 'has-text-success': props.row.new_views > 0 } ]},[_vm._v(_vm._s(_vm._f("formatNumber")(props.row.new_views)))])]),_c('b-table-column',{attrs:{"field":"tags","label":"Tags"}},[(props.row.tags)?_c('b-taglist',_vm._l((props.row.tags),function(t){return _c('b-tag',{key:t,attrs:{"rounded":"","closable":"","type":"is-info"},on:{"close":function($event){return _vm.deleteTag(props.row.id, t)}}},[_vm._v(" "+_vm._s(t)+" ")])}),1):_vm._e()],1),_c('b-table-column',{attrs:{"label":""}},[_c('b-button',{attrs:{"rounded":"","size":"is-small"},on:{"click":function($event){return _vm.addTag(props.row.id)}}},[_vm._v(" Add tag ")]),_c('b-modal',{attrs:{"active":_vm.isTagModalActive,"width":320},on:{"update:active":function($event){_vm.isTagModalActive=$event}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Add a tag to your post"}},[_c('b-input',{model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-right"},[_c('b-button',{staticClass:"has-margin-right-5",on:{"click":function($event){_vm.isTagModalActive = false;
                                _vm.tag = '';
                                _vm.currentTag = '';}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.saveTag()}}},[_vm._v(" Save ")])],1)])])])])])],1),_c('b-table-column',{attrs:{"width":"40"}},[_c('b-button',{attrs:{"type":"is-primary","tag":"router-link","to":_vm.getRouterLink(props.row.id),"icon-right":"finance"}})],1),_c('b-table-column',{attrs:{"width":"40"}},[_c('a',{attrs:{"href":_vm.generateVideoUrl(props.row.unique_id, props.row.post_id),"target":"_blank"}},[_c('b-button',{attrs:{"type":"is-link","outlined":"","icon-right":"link"}})],1)]),_c('b-table-column',{attrs:{"width":"40"}},[_c('b-button',{attrs:{"type":"is-danger","outlined":"","icon-right":"delete"},on:{"click":function($event){return _vm.toDelete(props.row.id)}}}),_c('b-modal',{attrs:{"active":_vm.isCardModalActive,"width":320},on:{"update:active":function($event){_vm.isCardModalActive=$event}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[_vm._v(" Are you sure ? ")])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column has-text-right"},[_c('b-button',{staticClass:"has-margin-right-5",on:{"click":function($event){_vm.isCardModalActive = false}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.deleteSearch()}}},[_vm._v(" Delete ")])],1)])])])])])],1)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }