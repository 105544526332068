<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div v-for="song in sortedTrending" :key="song.id" class="columns">
        <div class="column">
          <div class="box has-text-centered">
            <div class="columns is-mobile">
              <div class="column is-one-quarter">
                <figure class="image is-96x96">
                  <img :src="song.song_cover" class="is-rounded" />
                </figure>
              </div>
              <div class="column has-text-right">
                <p class="title">
                  <a :href="getUrl(song)" target="_blank">{{ song.music_name }}</a>
                </p>
                <p class="subtitle">
                  {{ song.music_author }}
                </p>
                <p class>{{ song.posts | formatNumber }} posts</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    sortedTrending() {
      const sorted = this.trending.songs.slice();
      // eslint-disable-next-line require-jsdoc
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const song1 = a.music_name.toUpperCase();
        const song2 = b.music_name.toUpperCase();
        let comparison = 0;
        if (song1 > song2) {
          comparison = 1;
        } else if (song1 < song2) {
          comparison = -1;
        }
        return comparison;
      }
      sorted.sort(compare);
      return sorted;
    },
    ...mapGetters("trending", ["trending"]),
  },
  methods: {
    getUrl(song) {
      return `https://www.tiktok.com/${song.link}`;
    },
  },
};
</script>

<style></style>
