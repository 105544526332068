<template>
  <div>
    <trending-nav />
    <trending-view />
  </div>
</template>

<script>
import TrendingNav from "@/components/trending/TrendingNav";
import TrendingView from "@/components/trending/TrendingView";

export default {
  components: { TrendingNav, TrendingView },
};
</script>

<style></style>
